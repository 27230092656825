import { NgModule } from '@angular/core';
import { ErrorReporterService } from './error-reporter.service';
import { LocalReporterService } from './local-reporter.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [ErrorReporterService, LocalReporterService],
})
export class ErrorReporterModule {}
