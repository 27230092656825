import { Component, ErrorHandler, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { NotificationType } from 'src/app/shared/component/notification/notification-type.enum';
import { SeasonResourceService } from 'src/app/shared/season-resource/season-resource.service';
import { ISeason } from 'src/app/shared/season-resource/season.interface';
import { CONFIG } from '../../../config';

@Component({
  selector: 'app-index',
  template: `<div class="container main">
      <mat-progress-spinner
        *ngIf="isLoading"
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
      <mat-card *ngIf="!isLoading && season">
        <mat-card-title
          >Saison {{ season.name }}&nbsp;: pré-inscriptions</mat-card-title
        >
        <mat-card-content>
          <p>
            Pour les pré-inscriptions<ng-container
              *ngIf="
                season.presubscription_cancel_only_date &&
                season.presubscription_opening_date
              "
            >
              reçues entre le
              {{ season.presubscription_opening_date | date: 'longDate' }} et le
              {{
                season.presubscription_cancel_only_date | date: 'longDate'
              }}</ng-container
            ><ng-container
              *ngIf="
                season.presubscription_cancel_only_date &&
                !season.presubscription_opening_date
              "
            >
              reçues avant le
              {{
                season.presubscription_cancel_only_date | date: 'longDate'
              }}</ng-container
            >, les places seront attri­buées selon l’ordre sui­vant :
          </p>
          <ol>
            <li>les adhérent⋅e⋅s de la sai­son pré­cé­dente&nbsp;;</li>
            <li>
              les per­sonnes de la famille proche d’un⋅e adhérent⋅e (frère,
              sœur, parent, enfant)&nbsp;;
            </li>
            <li>les habitant⋅e⋅s d’Anse&nbsp;;</li>
            <li>les pré-inscriptions dans l’ordre de récep­tion.</li>
          </ol>

          <p *ngIf="season.presubscription_cancel_only_date">
            Nous tra­vaille­rons sur l’at­tri­bu­tion des places à par­tir du
            <strong>{{
              season.presubscription_cancel_only_date | date: 'longDate'
            }}</strong
            >, aus­si toute pré-inscription reçue après cette date ne pour­ra
            pas être assu­rée d’être trai­tée selon l’ordre pré­ci­sé ci-dessus.
          </p>

          <p *ngIf="!season.is_open">
            Les pré-inscriptions pour {{ season.name }} commencent le
            <strong>{{
              season.presubscription_opening_date | date: 'longDate'
            }}</strong>
            à
            <strong>{{
              season.presubscription_opening_date | date: 'shortTime'
            }}</strong
            >.
          </p>
        </mat-card-content>
        <mat-card-actions *ngIf="season.is_open">
          <a
            mat-raised-button
            color="primary"
            [routerLink]="['/season', season.id, 'create-presubscription']"
            >Commencer la pré-inscription pour {{ season.name }}</a
          >
        </mat-card-actions>
      </mat-card>
      <mat-card *ngIf="!isLoading && !season && !error">
        <mat-card-title>{{ title }}</mat-card-title>
        <mat-card-content>
          <p>Les pré-inscriptions sont fermées pour le moment.</p>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="error">
        <app-notification
          [type]="notificationType.Error"
          title="Une erreur est survenue lors du chargement"
        ></app-notification>
      </mat-card>
    </div>
    <style>
      mat-progress-spinner {
        margin: 0 auto;
      }
    </style>`,
})
export class IndexComponent implements OnInit {
  public season?: ISeason;
  public error?: string;
  public isLoading = true;
  public title: string = CONFIG.app.name;
  public notificationType = NotificationType;

  constructor(
    private seasonResource: SeasonResourceService,
    private readonly errorHandler: ErrorHandler
  ) {}

  public ngOnInit(): void {
    this.seasonResource
      .list({ is_open: true })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (seasons: ISeason[]) => {
          this.season = seasons && seasons[0];
        },
        error: (error: Error) => {
          this.error = error.message;
          this.errorHandler.handleError(error);
        },
      });
  }
}
