import { NgModule } from '@angular/core';

import { ApiResourceService } from './api-resource.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [ApiResourceService],
})
export class ApiResourceModule {}
