import { Injectable } from '@angular/core';
import { IErrorReporter } from './error-reporter.interface';
import { CaptureContext, Severity } from '@sentry/types';

@Injectable()
export class LocalReporterService implements IErrorReporter {
  constructor() {}

  public captureException(error: Error): void {
    console.error(error);
  }

  captureMessage(message: string, captureContext?: CaptureContext | Severity) {
    console.log(captureContext, message);
  }
}
