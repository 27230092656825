import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NotificationType } from './notification-type.enum';

@Component({
  selector: 'app-notification',
  template: `<details [class]="type" open>
    <summary>
      <mat-icon>{{ icon }}</mat-icon
      >&nbsp;{{ title }}
    </summary>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </details>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnChanges {
  @Input()
  public type: NotificationType = NotificationType.Info;

  @Input()
  public title!: string;

  public icon?: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      switch (changes.type.currentValue) {
        case NotificationType.Error:
          this.icon = 'error';
          break;
        case NotificationType.Warning:
          this.icon = 'warning';
          break;
        case NotificationType.Success:
          this.icon = 'done';
          break;
        default:
          this.icon = 'info';
      }
    }
  }
}
