import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResourceService } from '../api-resource/api-resource.service';
import { IUser } from '../users-resource/users.interface';
import { ICredentials, ICredentialsRaw } from './credentials.interface';

const STORAGE_KEY = 'credentials';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentCredentials: BehaviorSubject<ICredentials | null> =
    new BehaviorSubject(loadCredentials());
  private nextUrl?: string;

  constructor(
    private readonly http: HttpClient,
    private readonly apiResourceService: ApiResourceService,
    private readonly router: Router
  ) {}

  public isAuthenticated(): Observable<boolean> {
    return this.currentCredentials
      .asObservable()
      .pipe(
        map(
          (credentials: ICredentials | null) =>
            Boolean(credentials) && !isExpired(credentials)
        )
      );
  }

  public isAdmin(): Observable<boolean> {
    return this.currentCredentials
      .asObservable()
      .pipe(
        map((credentials: ICredentials | null) =>
          Boolean(
            credentials && credentials.user.is_admin && !isExpired(credentials)
          )
        )
      );
  }

  public getUser(): Observable<IUser | null> {
    return this.currentCredentials
      .asObservable()
      .pipe(
        map(
          (credentials) =>
            (credentials && !isExpired(credentials) && credentials.user) || null
        )
      );
  }

  public getToken(): Observable<string | null> {
    return this.currentCredentials
      .asObservable()
      .pipe(
        map(
          (credentials) =>
            (credentials &&
              !isExpired(credentials) &&
              credentials.access_token) ||
            null
        )
      );
  }

  public login(
    emailAddress: string,
    password: string
  ): Observable<ICredentials> {
    return this.http
      .post<ICredentialsRaw>(
        this.apiResourceService.getApiUrl('/users/login'),
        {
          email_address: emailAddress,
          password,
        }
      )
      .pipe(
        map(mapCredentials),
        tap((credentials) => {
          this.currentCredentials.next(credentials);
          localStorage.setItem(STORAGE_KEY, JSON.stringify(credentials));
        })
      );
  }

  public logout(): void {
    localStorage.removeItem(STORAGE_KEY);
    this.currentCredentials.next(null);
  }

  public setUrlAfterLogin(): void {
    if (this.router.url !== '/user/login') {
      this.nextUrl = this.router.url;
    } else if (!this.nextUrl) {
      this.nextUrl = '/';
    }
  }

  public getNextUrlAfterLogin(): string {
    return this.nextUrl!;
  }
}

function mapCredentials(credentials: ICredentialsRaw): ICredentials {
  return {
    ...credentials,
    expirationTimestamp: Date.now() + credentials.expires_in * 1e3,
  };
}

function loadCredentials(): ICredentials | null {
  const loaded = localStorage.getItem(STORAGE_KEY);

  if (!loaded) {
    return null;
  }

  const credentials = JSON.parse(loaded);

  if (isExpired(credentials)) {
    localStorage.removeItem(STORAGE_KEY);
    return null;
  }

  return credentials;
}

function isExpired(credentials: ICredentials | null): boolean {
  return !credentials || credentials.expirationTimestamp <= Date.now();
}
