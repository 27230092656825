import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUser } from 'src/app/shared/users-resource/users.interface';
import { CONFIG } from '../../../config';
import { AuthenticationService } from '../../shared/authentication/authentication.service';

@Component({
  selector: 'app-toolbar',
  template: `<mat-toolbar color="primary">
    <div class="container">
      <button mat-button class="menu" [matMenuTriggerFor]="mainMenu">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/" class="title">{{ title }}</a>
      <a
        mat-button
        class="toolbar-button"
        [routerLink]="['/presubscriptions']"
        *ngIf="isAuthenticated | async"
        >Mes demandes</a
      >
      <span class="fill-remaining-space"></span>
      <a
        mat-button
        class="toolbar-button"
        [routerLink]="['/admin']"
        *ngIf="isAdmin | async"
        >Admin</a
      >
      <a
        mat-button
        *ngIf="isAnonymous | async"
        [routerLink]="['/user', 'login']"
        class="toolbar-button"
        (click)="onSignInClick()"
        >Connexion</a
      >
      <button
        mat-button
        *ngIf="isAuthenticated | async"
        [matMenuTriggerFor]="userMenu"
        class="toolbar-button"
      >
        {{ (user | async)!.name }}
      </button>

      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="onSignOutClick()">Déconnexion</button>
      </mat-menu>

      <mat-menu #mainMenu="matMenu">
        <button
          mat-menu-item
          [routerLink]="['/presubscriptions']"
          *ngIf="isAuthenticated | async"
        >
          Mes demandes
        </button>
        <button mat-menu-item [routerLink]="['/admin']" *ngIf="isAdmin | async">
          Admin
        </button>

        <mat-divider *ngIf="isAuthenticated | async"></mat-divider>

        <button
          mat-menu-item
          *ngIf="isAnonymous | async"
          [routerLink]="['/user', 'login']"
          (click)="onSignInClick()"
        >
          Connexion
        </button>
        <button
          mat-menu-item
          *ngIf="isAuthenticated | async"
          (click)="onSignOutClick()"
        >
          Déconnexion
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>`,
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  public title: string = CONFIG.app.name;
  public isAuthenticated: Observable<boolean>;
  public isAnonymous: Observable<boolean>;
  public isAdmin: Observable<boolean>;
  public user: Observable<IUser | null>;

  public constructor(
    private readonly authenticationService: AuthenticationService
  ) {
    this.isAuthenticated = this.authenticationService.isAuthenticated();
    this.isAnonymous = this.isAuthenticated.pipe(map((x) => !x));
    this.user = this.authenticationService.getUser();
    this.isAdmin = this.authenticationService.isAdmin();
  }

  public onSignInClick() {
    this.authenticationService.setUrlAfterLogin();
  }

  public onSignOutClick() {
    this.authenticationService.logout();
  }
}
