import { Component, OnInit } from '@angular/core';
import { CONFIG } from '../config';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: `<app-toolbar></app-toolbar> <router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title) {}

  public ngOnInit(): void {
    this.titleService.setTitle(CONFIG.app.name);
  }
}
