import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeasonResourceService } from './season-resource.service';
import { HttpClientModule } from '@angular/common/http';
import { SeasonResolver } from './season.resolver';
import { ApiResourceModule } from '../api-resource/api-resource.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, ApiResourceModule],
  exports: [],
  providers: [SeasonResourceService, SeasonResolver],
})
export class SeasonResourceModule {}
