import { ErrorHandler, Injectable } from '@angular/core';
import { CaptureContext, Severity } from '@sentry/types';
import { CONFIG } from 'src/config';
import { IErrorReporter } from './error-reporter.interface';
import { LocalReporterService } from './local-reporter.service';

interface Sentry extends IErrorReporter {
  init: (config: { dsn: string }) => void;
}

@Injectable()
export class ErrorReporterService implements ErrorHandler {
  private reporter: Promise<IErrorReporter>;

  constructor(localReporter: LocalReporterService) {
    if (CONFIG.app.isProduction && CONFIG.sentry.dsn !== null) {
      const sentry = import('@sentry/browser');

      this.reporter = sentry.then((loaded: Sentry) => {
        if (CONFIG.sentry.dsn) {
          loaded.init({
            dsn: CONFIG.sentry.dsn!,
          });

          return loaded;
        }

        return localReporter;
      });
    } else {
      this.reporter = Promise.resolve(localReporter);
    }
  }

  public handleError(error: Error | object): void {
    console.error(error);

    this.reporter.then((reporter: IErrorReporter) => {
      if (error instanceof Error) {
        reporter.captureException(error);
      } else {
        reporter.captureMessage('ERROR', {
          level: Severity.Error,
          contexts: error,
        });
      }
    });
  }

  public log(message: string, context: CaptureContext | Severity) {
    this.reporter.then((reporter: IErrorReporter) => {
      reporter.captureMessage(message, context);
    });
  }
}
