import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { RouterModule } from '@angular/router';
import { IsAuthenticatedGuard } from './is-authenticated.guard';
import { IsAdminGuard } from './is-admin.guard';

@NgModule({
  imports: [RouterModule],
  exports: [],
  declarations: [],
  providers: [AuthenticationService, IsAuthenticatedGuard, IsAdminGuard],
})
export class AuthenticationModule {
  constructor(@Optional() @SkipSelf() parentModule?: AuthenticationModule) {
    if (parentModule) {
      throw new Error(
        'AuthenticationModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
