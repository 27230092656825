import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { IndexModule } from './pages/index/index.module';
import { AuthenticationModule } from './shared/authentication/authentication.module';
import { ErrorReporterModule } from './shared/error-reporter/error-reporter.module';
import { ErrorReporterService } from './shared/error-reporter/error-reporter.service';

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IndexModule,
    ErrorReporterModule,
    AuthenticationModule,
    ToolbarModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: ErrorHandler, useClass: ErrorReporterService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
