import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { ApiResourceService } from '../api-resource/api-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { IEditSeasonValues, ISeason, ISeasonRaw } from './season.interface';

@Injectable()
export class SeasonResourceService {
  public constructor(
    private http: HttpClient,
    private readonly apiResourceService: ApiResourceService,
    private readonly authService: AuthenticationService
  ) {}

  public list(params: {
    is_open?: boolean;
    is_open_for_admin?: boolean;
  }): Observable<ISeason[]> {
    return this.authService.getToken().pipe(
      take(1),
      flatMap((token) => {
        return this.http.get<ISeasonRaw[]>(
          this.apiResourceService.getApiUrl(`/seasons`),
          {
            headers: this.apiResourceService.getAuthorizationHeader(token),
            params: {
              ...(params.is_open !== undefined
                ? { is_open: `${params.is_open}` }
                : {}),
              ...(params.is_open_for_admin !== undefined
                ? { is_open_for_admin: `${params.is_open_for_admin}` }
                : {}),
            },
          }
        );
      }),
      map((seasons: ISeasonRaw[]): ISeason[] => (seasons || []).map(mapSeason))
    );
  }

  public get(id: number): Observable<ISeason> {
    return this.http
      .get<ISeasonRaw>(
        this.apiResourceService.getApiUrl(`/seasons/${encodeURIComponent(id)}`)
      )
      .pipe(map(mapSeason));
  }

  public edit(id: number, season: IEditSeasonValues): Observable<ISeason> {
    return this.authService.getToken().pipe(
      take(1),
      flatMap((token) =>
        this.http.put<ISeasonRaw>(
          this.apiResourceService.getApiUrl(`/seasons/${id}`),
          season,
          {
            headers: this.apiResourceService.getAuthorizationHeader(token),
          }
        )
      ),
      map(mapSeason)
    );
  }

  public create(season: IEditSeasonValues): Observable<ISeason> {
    return this.authService.getToken().pipe(
      take(1),
      flatMap((token) =>
        this.http.put<ISeasonRaw>(
          this.apiResourceService.getApiUrl(`/seasons`),
          season,
          {
            headers: this.apiResourceService.getAuthorizationHeader(token),
          }
        )
      ),
      map(mapSeason)
    );
  }

  public destroy(id: number): Observable<void> {
    return this.authService.getToken().pipe(
      take(1),
      flatMap((token) =>
        this.http.delete<void>(
          this.apiResourceService.getApiUrl(`/seasons/${id}`),
          {
            headers: this.apiResourceService.getAuthorizationHeader(token),
          }
        )
      )
    );
  }
}

function mapSeason(raw: ISeasonRaw): ISeason {
  return {
    ...raw,
    presubscription_closing_date: mapDate(raw.presubscription_closing_date),
    presubscription_opening_date: mapDate(raw.presubscription_opening_date),
    presubscription_cancel_only_date: mapDate(
      raw.presubscription_cancel_only_date
    ),
  };
}

function mapDate(value: string | null | undefined): Date | undefined {
  return (value && new Date(value)) || undefined;
}
